import React from 'react';
// import styled from 'styled-components';



const Footer = (props) => {

    return (
        <div className="footer" style={{ padding: "2rem" }}>
            <div className="page-wrapper">

                <figure className="image is-32x32" style={{ marginBottom: "1rem" }}>
                    <img src={"ZahrLogoWFilled.png"} alt="Zahr's Logo" />
                </figure>


                <div className="columns is-vcentered">
                    <div className="column is-half">
                        <div>
                            <div className="header4" style={{ color: "white" }}>Zahr Lyttle</div>
                            <div style={{ color: "white" }}>If you're looking for a UX Engineer, have a website/app idea in mind, or are just looking for some advice about product design, please feel free to contact me!</div>
                        </div>
                    </div>
                    <div className="column">
                        <p className="level-item"><small className="is-white is-centered">© 2021 All rights reserved. – Designed & Coded by Zahr Lyttle</small></p>
                    </div>
                </div>

                {/* <nav className="level">
                    <div className="level-left" style={{ width: "50%" }}>
                        <div>
                            <div className="header4" style={{ color: "white" }}>Zahr Lyttle</div>
                            <div style={{ color: "white" }}>If you're looking for a UX Engineer, have a website/app idea in mind, or are just looking for some advice about product design, please feel free to contact me!</div>
                        </div>
                    </div>

                    <div className="level-right">
                        <p className="level-item"><small className="is-white is-centered">© 2021 All rights reserved. – Designed & Coded by Zahr Lyttle</small></p>
                    </div>
                </nav> */}

            </div>
        </div>
    )
}
export default Footer;