import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const IOS14conceptPage = (props) => {
    const pageName = "iOS 14 Concept";
    const projTheme = "white";

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            {/* Custom Navbar */}
            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} color={projTheme} />


            <div className="is-background-white inverted">

                {/* Header section */}
                <img src={"images/ios14/ios14c-main.png"} alt="iOS14 Concept Header" />

                {/* Background section */}
                <div className="ios14-section">
                    <p className="ios14-subtext" style={{ textAlign: "center", fontWeight: "700", width: '75%', margin: "auto" }}>
                        <span className="ios14-title">
                            What's in this iOS14 Concept?
                    </span>
                        <br />
                        <br />
                        This concept brings widgets and modules to iPhone. It adds customizability and more functionality, yet still adheres to a robust design system.
                    <br />
                        <br />
                        The iOS 14 concept delivers immense value and functionality to users who seek it, while keeping the OS accessible for users who prefer a simpler experience.
                    <br />
                        <br />
                        (02/2020)
                </p>
                    <img src={"images/ios14/ios14-mainHigh.gif"} alt="iOS14 Concept Showcase" className="" />
                </div>

                {/* Core problems section */}
                <div >
                    <img src={"images/ios14/ios13rd-coreproblemsA.png"} alt="iOS14 Core Problems" />
                </div>

                {/* Unified Lockscreen section */}
                <div className="ios14-section columns is-vcentered is-mobile">
                    <div className="column is-half">
                        <img src={"images/ios14/ios14-vidLS1.gif"} alt="iOS14 Unified Lockscreen Highlight" />
                    </div>
                    <div className="column" >
                        <p className="ios14-title">
                            Unified Lock Screen
                </p>
                        <p className="ios14-subtext">
                            With a unified view lock screen, both notifications and widgets can be accessed in a single view. Through the use of accordions
                users can choose the amount of content they want visible.
                <br />
                            <br />
                            iOS intelligently shows the accordions based on the state the iPhone owner last left their device.
                </p>
                    </div>
                </div>

                {/* Customizable Homescreen section */}
                <div className="ios14-section columns is-vcentered is-mobile">
                    <div className="column" >
                        <p className="ios14-title">
                            Customizable Home Screen
                </p>
                        <p className="ios14-subtext">
                            With a customizable home screen and widgets, iPhone offers limitless combinations of functionality for users. Each iPhone
                can provide a unique experience tailored to it’s owners preferences and self expression.
                <br />
                            <br />
                            iOS is now a part of the family of Pro devices.
                </p>
                    </div>
                    <div className="column is-half">
                        <img src={"images/ios14/ios14-vidHS1.gif"} alt="iOS14 Customizable Homescreen Highlight" />
                    </div>
                </div>

                {/* How To Customize Homescreen section */}
                <div className="ios14-section">
                    <p className="ios14-title" style={{ textAlign: "center", margin: "auto" }}>
                        How to Customize the Home Screen
                    </p>
                    <div className="columns is-vcentered is-mobile">
                        <div className="column is-half">
                            <img src={"images/ios14/ios13rd-lofiVid.gif"} alt="iOS14 How To Customize Homescreen" />
                        </div>
                        <div className="column" >
                            <p className="ios14-subtext" style={{ textAlign: "center", margin: "auto" }}>
                                <span className="ios14-subtext" style={{ fontWeight: "600", color: "rgb(44, 44, 46)", textAlign: "center", margin: "auto" }}>
                                    Tap or Drag
                        </span>
                                <br />
                                <br />
                                Customization is possible via iOS’s ‘Edit Home Screen’ state. As the app icons wiggle around, new icons around the app allow for enabling the widget view and resizing the app icon.
                    </p>
                        </div>
                    </div>
                </div>

                {/* Homescreen Anatomy section */}
                <div className="ios14-section" style={{ justifyContent: "center", width: "66%", margin: "auto" }}>
                    <p className="ios14-title" style={{ textAlign: "center" }}>
                        Home Screen Anatomy
                    </p>
                    <p className="ios14-subtext" style={{ textAlign: "center", margin: "auto" }}>
                        A gridded layout allows apps to be placed and resized anywhere on the homescreen with enough space. Controls for each app are located at the corners of each of their respective app icons. With iOS 14 all app icons will automatically support resizing, and widgets can be enabled if and once developers create and publish those views for their app.
                    </p>
                    <img src={"images/ios14/ios14-HSanatomy.png"} alt="iOS14 Concept Homescreen Anatomy" />
                </div>

                {/* Customization section */}
                <div >
                    <img src={"images/ios14/ios14rd-colors.png"} alt="iOS14 Customization" />
                </div>

                {/* Landscape Mode section */}
                <div className="ios14-section">
                    <div className="columns is-vcentered">
                        <div className="column is-half">
                            <p className="ios14-title" >
                                Landscape Mode Support
                    </p>
                        </div>
                        <div className="column" >
                            <p className="ios14-subtext" >
                                By bringing landscape support from iPadOS to iOS, users can better utilize their devices in any situation. iOS will now automatically adjust to match your viewing preference, and can also be locked in to maintain a single homescreen orientation.
                    </p>
                        </div>
                    </div>
                    <img src={"images/ios14/ios14-landscapepic.png"} alt="iOS14 Concept Landscape Mode Highlight" />
                </div>

                {/* Introducing Modules section */}
                <div className="ios14-section" style={{ justifyContent: "center", width: "66%", margin: "auto" }}>
                    <p className="ios14-title" style={{ textAlign: "center" }}>
                        Introducing Modules
                    </p>
                    <p className="ios14-subtext" style={{ textAlign: "center", margin: "auto" }}>
                        Modules are new components and ways to interact with native iOS apps and apps running in the background. No longer will applications need to take up the entire screen when users are multi-tasking.
                    </p>
                    <div className="columns" style={{ margin: "4rem auto" }}>
                        <div className="column is-one-third" style={{ textAlign: "center" }}>
                            <p className="ios14-subtext">
                                Phone
                    </p>
                            <img src={"images/ios14/ios14-phonemoduleA.gif"} alt="iOS14 Concept Phone Module" />
                        </div>
                        <div className="column is-one-third" style={{ textAlign: "center" }}>
                            <p className="ios14-subtext">
                                Media
                    </p>
                            <img src={"images/ios14/ios14-videoplayermodule.gif"} alt="iOS14 Concept Media Module" />
                        </div>
                        <div className="column is-one-third" style={{ textAlign: "center" }}>
                            <p className="ios14-subtext">
                                Siri
                    </p>
                            <img src={"images/ios14/ios14-sirimodule.gif"} alt="iOS14 Concept Siri Module" />
                        </div>
                    </div>
                </div>

                {/* Footer section */}
                <div className="ios14-section" style={{ borderTop: "1px solid rgb(174, 174, 178)", paddingBottom: "2rem" }}>
                    <div className="columns is-mobile" style={{ marginTop: "1rem" }}>
                        <div className="column is-half">
                            <p className="ios14-footer-text" style={{ fontWeight: "600", margin: "1rem auto" }}>About</p>
                            <p className="ios14-footer-text" >
                                This concept was created February 2020 by me, Zahr Lyttle. Feel free to share the concept and website.
                            <br /><br />
                                For any questions please contact me using the contact page or by email.
                            </p>
                        </div>
                        <div className="column is-half">
                            <p className="ios14-footer-text" style={{ fontWeight: "600", margin: "1rem auto" }}>License</p>
                            <p className="ios14-footer-text" >
                                This work is licensed under a Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License
                            </p>
                        </div>
                    </div>
                    <p className="ios14-footer-text" style={{ fontSize: ".75rem", margin: "2rem auto", textAlign: "center" }}>
                        Apple, the Apple logo, Mac, iPad and iPadOS are trademarks of Apple Inc., registered in the U.S. and other countries.
                    <br /><br />
                        This project is not associated with Apple Inc. or any other app shown.
                    </p>
                </div>

            </div>

        </motion.div >
    )
}
export default IOS14conceptPage;