import React from "react";
import "./style.css";

export default function DNToggle(props) {

    const toggleTheme = () => {
        // Change theme using ThemeProvider
        props.theme === 'light' ? props.setTheme('dark') : props.setTheme('light');
        console.log("running dntoggle")
        if (props.theme === 'dark') {
            document.documentElement.classList.remove("dark-mode");
            document.querySelectorAll('.inverted').forEach((result) => {
                result.classList.remove('invert');
            })
        }
        else if (props.theme === 'light') {
            document.documentElement.classList.add("dark-mode");
            document.querySelectorAll('.inverted').forEach((result) => {
                result.classList.add('invert');
            })
        }
        // document.documentElement.classList.toggle("dark-mode");
        // document.querySelectorAll('.inverted').forEach((result) => {
        //     result.classList.toggle('invert');
        // })
    };

    return (
        <div onClick={toggleTheme} className={`toggle${props.theme === 'dark' ? " night" : ""}`}>
            <div className="notch">
                <div className="crater" />
                <div className="crater" />
                <div className="crater" />
            </div>
            <div>
                <div className="shape sm" />
                <div className="shape sm" />
                <div className="shape md" />
                <div className="shape lg" />
            </div>
        </div>
    );
}