import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionLeft from './ProjectSections/ProjSectionLeft';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const WhayPage = (props) => {
    const pageName = "Whay";
    const projTheme = ["#8E2DE2", "linear-gradient(270deg, rgba(254,87,98,1), rgba(255,61,89,1))"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} />
            <ProjSectionHeader
                color={'white'}
                title={"Whay"}
                roleText={[
                    "UX Design",
                    "UX Research",
                    "Visual Design",
                    "Creative Direction",
                    "Front End Development",
                ]}
                orgText={
                    "Whay"
                }
                toolsText={[
                    "Pen & Paper",
                    "Illustrator",
                    "Photoshop",
                    "Google Maps/Places API",
                    "Foursquare API",
                    "Swift",
                    "SwiftUI",
                ]}
                executiveSummary={
                    [
                        "Many people struggle with finding things to do, even me! One day when I had trouble figuring out something fun to do nearby, I had the idea to create a solution called Whay (What’s Happening Around You)!",
                        "My friend and I immediately started working on the original idea and thinking of features and different scenarios where an app like this would come in handy.",
                        "We conducted research to validate our idea and identify our target market, and after months of work we were able to build and launch an MVP of Whay on the Apple App Store!",
                        // 'Many people struggle with this exact predicament, even me! One day when I had trouble figuring out something fun to do nearby, I had the idea to create a solution called Whay ( What’s Happening Around You)! My friend and I immediately started working on the original idea and thinking of features and different scenarios where an app like this would come in handy. We conducted research to validate our idea and identify our target market, and after months of work we were able to build and launch an MVP of Whay on the Apple App Store! '
                    ]
                }
                carouselImages={[
                    "images/whay/whay-OurSolution.png",
                ]}
            />

            <ProjSectionLeft
                title={"The Problem"}
                compText={
                    [
                        "People struggle finding relevant venues and activites to do around them.",
                        "The current process is ineffective as it requires people to reference multiple tools (like Google or Yelp), each producing minimal, unreliable, and/or irrelevant information.",
                        "If finding something to do takes too long or becomes frustrating, people ultimately choose to go to familiar places or to not go out at all.",
                        "These end-results leave people feeling unfulfilled and causes businesses to miss out on new customers.",
                        // 'People struggle finding relevant venues and activites to do around them. The current process is ineffective as it requires people to reference multiple tools, each producing minimal, unreliable, and/or irrelevant information. If finding something to do takes too long or becomes frustrating, people ultimately choose to go to familiar places or to not go out at all. These end-results leave people feeling unfulfilled and causes businesses to miss out on new customers.'
                    ]
                }
                carouselImages={[
                    "images/whay/whay-prob1.png",
                    "images/whay/whay-prob2.png",
                    "images/whay/whay-prob3.png",
                ]}
            />
            <ProjSectionLeft
                title={"Initial Research, and Validation"}
                compText={
                    [
                        "Before developing an MVP, we needed to validate that finding things to do/places to go was a real and common issue, not something only we felt was an issue.",
                        "Considering we did not have a large budget, we decided to put our experiences as a UX Designer and UX Researcher to the test and perform general use case/competitor research, as well as interview potential users.",
                        "Our goal here was to truly understand the issue, identify our target audience, and familiarize ourselves with possible competitors.",
                        "By combining the results from our research and the results from our interviews, we had enough quantitative and qualitative information to consider the need for Whay validated!",
                        "From here, we created a list of key features, and developed an MVP while working closely with potential users.",
                        // 'Before developing an MVP, we needed to validate that finding things to do/places to go was a real and common issue, not something only we felt was an issue. Considering we did not have a large budget, we decided to put our experiences as a UX Designer and UX Researcher to the test and perform general use case/competitor research, as well as interview potential users. Our goal here was to truly understand the issue, identify our target audience, and familiarize ourselves with possible competitors. By combining the results from our research and the results from our interviews, we had enough quantitative and qualitative information to consider the need for Whay validated! From here, we created a list of key features, and developed an MVP while working closely with potential users.'
                    ]
                }
                carouselImages={[
                    "images/whay/whay-IRV1.jpeg",
                    "images/whay/whay-IRV2.jpeg",
                    "images/whay/whay-IRV3.jpeg",
                    "images/whay/whay-IRV4.jpeg",
                    "images/whay/whay-IRV5.jpeg",
                    "images/whay/whay-IRV6.jpeg",

                ]}
            />
            <ProjSectionLeft
                title={"The Solution...Whay"}
                compText={
                    [
                        "After the initial research and validation, we began building the Whay MVP.",
                        "Although we had many features planned, we saved many of them for later versions as wanted to start at a basic level and then continue building based on user feedback.",
                        "Here is a preview of the MVP/first publically available version of Whay we put on the Apple App Store!",
                        // 'After the initial research and validation, we began building an MVP of Whay. Although we had many features planned, we wanted to start at a basic level and then build off of that using user feedback. Here is a preview of the first publically available version of Whay we put on the Apple App Store!'
                    ]
                }
                carouselImages={[
                    "images/whay/Whay-RotatoDemoVid.gif",
                    "images/whay/whay-OurSolution.png",
                ]}
            />


        </motion.div>
    )
}
export default WhayPage;