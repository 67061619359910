export const ProjectData = [
    {
        title: "Meridian",
        category: "UX Design",
        thumbnailSrc: "images/meridian/meridianThumb.png",
        description: "The medical cost estimator tool that helps people save money and makes finding care easier.",
        route: "/meridian"
    },
    {
        title: "iOS 14 Concept",
        category: "UX Design",
        thumbnailSrc: "images/ios14/ios14Thumb.png",
        description: "A concept of a potential iOS14. (created before release of Apple's official iOS14)",
        route: "/ios14concept"
    },
    {
        title: "GP Fulfillment Hub",
        category: "UX Design",
        thumbnailSrc: "images/gpfh/gpfhThumb1.png",
        description: "Complete redesign of the GP Fulfillment Hub application.",
        route: "/gpfulfillmenthub"
    },
    {
        title: "UI Toolkit",
        category: "UX Design",
        thumbnailSrc: "images/uitoolkit/uitoolkitThumb.png",
        description: "A reimagined and redesigned company design system.",
        route: "/uitoolkit"
    },
    {
        title: "Whay",
        category: "UX Design",
        thumbnailSrc: "images/whay/whayThumb.jpg",
        description: "Finding things to do around you is a lot easier than you think. Let us show you the Whay!",
        route: "/whay"
    },
    {
        title: "MyIHR",
        category: "UX Design",
        thumbnailSrc: "images/myihr/ihrThumb.png",
        description: "A global hackathon project aimed at solving 'Fragmentation' in the healthcare system.",
        route: "/myihr"
    },
    // {
    //     title: "Intelligent EDI",
    //     category: "UX Design",
    //     thumbnailSrc: "images/iedi/iediThumb1.png",
    //     description: "Optum's Intelligent EDI (IEDI) clearinghouse.",
    //     route: "/iedi"
    // },
    {
        title: "EngineeredFitness",
        category: "Front-End Development",
        thumbnailSrc: "images/engineeredfitness/EngineeredFitnessThumb.jpg",
        description: "The website for a new fitness and personal health company.",
        route: "/engineeredfitness"
    },
    {
        title: "DocFind",
        category: "Visual Design",
        thumbnailSrc: "images/docfind/DocFindThumb.png",
        description: "The application that makes finding a healthcare specialist easier than ever before.",
        route: "/docfind"
    },
    {
        title: "Grocery Delivery App",
        category: "Visual Design",
        thumbnailSrc: "images/grocerydeliveryapp/GroceryDeliveryAppThumb.png",
        description: "Logo and branding concepts for a new grocery delivery application.",
        route: "/grocerydeliveryapp"
    },
    {
        title: "Huneebee Project",
        category: "Visual Design",
        thumbnailSrc: "images/huneebeeproject/HuneebeeProjectThumb.png",
        description: "Logo and branding concepts for a small non-profit company.",
        route: "/huneebeeproject"
    },
];