import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionChallengeGoals from './ProjectSections/ProjSectionChallengeGoals';
import ProjSectionLeft from './ProjectSections/ProjSectionLeft';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const MyIHRPage = (props) => {
    const pageName = "My IHR";
    const projTheme = ["#8E2DE2", "linear-gradient(270deg, #123597, #0E197D)"];


    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} />
            <ProjSectionHeader
                color={'white'}
                title={"My IHR"}
                roleText={[
                    "UX Design",
                    "UX Research",
                    "Visual Design",
                    "Creative Direction",
                ]}
                orgText={
                    "Optum"
                }
                toolsText={[
                    "Pen & Paper",
                    "Sketch",
                    "Axure",
                    "Illustrator",
                    "Photoshop",
                    "HTML",
                    "CSS",
                    "Angular",
                ]}
                confidential={false}
                executiveSummary={
                    [
                        "MyIHR is an application that gives users full access and control of their health records in one place, it also has the ability to mitigate health issues by preemptively suggesting actions if it notices specific trends.",
                        "This application stemmed from a company-wide United Healthcare (UHC) Global Hackathon, where teams of 4 were asked to create a demo-able solution for 1 of 3 major healthcare issues in just 24 hours.",
                        "My team chose to solve ‘Fragmentation’, specifically, how an Integrated Health Record could solve the issue of patient data fragmentation when someone is in need of care.",
                        "I was on a cross-discipline team and my primary role was to lead UX research and design, and then code as much as I could before the time was up!",
                        "(Spoiler Alert) Unfortunately we did not win the hackathon, but the company’s R&D team really liked our idea and they invited us to present it to their larger team separately from the Hackathon and potentially use our work in the future. Im happy to say they did end up using our work in some of their own projects.",
                        // 'MyIHR is an application that gives users full access and control of their health records in one place, it also has the ability to mitigate health issues by preemptively suggesting actions if it notices specific trends. This application stemmed from a company-wide United Healthcare (UHC) Global Hackathon, where teams of 4 were asked to create a demo-able solution for 1 of 3 major healthcare issues in just 24 hours. My team chose to solve ‘Fragmentation’, specifically, how an Integrated Health Record could solve the issue of patient data fragmentation when someone is in need of care. I was on a cross-discipline team and my primary role was to lead UX research and design, and then code as much as I could before the time was up. (Spoiler Alert) Unfortunately we did not win the hackathon, but the company’s R&D team really liked our idea and they invited us to present it to their larger team separately from the Hackathon and potentially use our work in the future. Im happy to say they did end up using our work in some of their own projects.'
                    ]
                }
                carouselImages={[
                    "images/myihr/ihr-ES1.jpeg",
                    "images/myihr/ihr-ES2.jpeg",
                    "images/myihr/ihr-ES3.jpeg",
                ]}
            />
            <ProjSectionChallengeGoals
                color={"rgb(250, 250, 250)"}
                title={"Challenge/Goals"}
                compText={
                    [
                        "The challenge and goal of the hackathon was to develop a demo-able solution for one of the 3 available problem statements in just 24 hours!",
                        "We had the freedom to approach the problem and deliver whatever we wanted, but the main requirement was that we needed a working prototype by the end of the event.",
                        "At the end of the hackathon, each team competed by presenting their idea to panels of internal business/tech leaders in order to get to the final round and win prizes/awards during a company wide stream. We had a chance to ask questions, received some sample data to work with, and then the timer started!",
                        // 'The challenge and goal of the hackathon was to develop a demo-able solution for one of the 3 available problem statements in just 24 hours! We had the freedom to approach the problem and deliver whatever we wanted, but the main requirement was that we needed a working prototype by the end of the event. At the end of the hackathon, each team competed by presenting their idea to panels of internal business/tech leaders in order to get to the final round and win prizes/awards during a company wide stream. We had a chance to ask questions, received some sample data to work with, and then the timer started! '
                    ]
                }
            />
            <ProjSectionLeft
                bColor={"white"}
                title={"Understanding the Problem, and Research"}
                compText={
                    [
                        "Before starting any design or development work, we knew it was critical to first take the time to research the problem and truly understand the issue from our potential users point of view",
                        "We defined the problem, uncovered who the target user would be, and identified key pain points so that we could begin ideating and creating a solution for ‘Fragmentation’.",
                        // 'Before starting any design or development work, we knew it was critical to first take the time to research the problem and truly understand the issue from our potential users point of view. We defined the problem, uncovered who the target user would be, and identified key pain points so that we could begin ideating and creating a solution for ‘Fragmentation’’.'
                    ]
                }
                carouselImages={[
                    "images/myihr/ihr-UPR1.jpeg",
                    "images/myihr/ihr-UPR2.jpeg",
                    "images/myihr/ihr-UPR3.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"white"}
                title={"Our Solution, and Key Features"}
                compText={
                    [
                        'A bunch of whiteboarding, many design iterations, a full night of coding, and many cups of caffeine later we finally had our demo-able prototype and a deck detailing our work.',
                        "Here is a look at what we came up with!"
                    ]
                }
                carouselImages={[
                    "images/myihr/ihr-OSKF1.jpeg",
                    "images/myihr/ihr-OSKF2.jpeg",
                    "images/myihr/ihr-OSKF3.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"white"}
                title={"Control of Your Health Records"}
                compText={
                    [
                        "Since it’s difficult for people to share their health data with any given healthcare provider, we understood that it was critical for our application to centralize access and control of user's health records in one location.",
                        "We discovered that users wanted to know exactly who had access to which health records and that this data was being managed securely. So, we made sure to prioritize security and transparency in our designs.",
                        // "Since it’s difficult for people to share their health data with any given healthcare provider, we understood that it was critical for our application to centralize access and control of user's health records in one location. We discovered that users wanted to know exactly who had access to which health records and that this data was being managed securely. So, we made sure to prioritize security and transparency in our designs."
                    ]
                }
                carouselImages={[
                    "images/myihr/ihr-F1.jpeg",
                    "images/myihr/ihr-F1-1.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"white"}
                title={"Health Tracker"}
                compText={
                    [
                        "A huge item we continued to notice in our user research, was that people felt they were unaware of their current health or did not know how to handle issues that came up outside of their yearly doctor visit.",
                        "Uncovering this gave us the idea to implement a way to communicate with doctors faster and more accurately than a message box.",
                        "We had the idea of a daily health tracker where a user could quickly document any unusual symptoms they were feeling in the app, which would be available to their primary doctor.",
                        "With the application, doctors could treat patients using relevant data at any time care is needed.",
                        // "A huge item we continued to notice when talking to potential users, and in our research, was that people felt they were unaware of their current health or did not know how to handle issues that came up outside of their yearly doctor visit. Uncovering this gave us the idea to implement a way to communicate with doctors faster and more accurately than a message box. We had the idea of a daily health tracker where a user could quickly document any unusual symptoms they were feeling in the app, which would be available to their primary doctor. With the application, doctors could treat patients using relevant data at any time care is needed."
                    ]
                }
                carouselImages={[
                    "images/myihr/ihr-F2.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"white"}
                title={"Internet of Things (IoT) Integration"}
                compText={
                    [
                        "In the age of the ‘Internet of Things,’ people have devices that collect tons of data about them, but little of that data gets used properly to benefit people's health.",
                        "In conjunction with the Health Tracker’ feature, we wanted to provide users a way to integrate their device/wearable data into their IHR app, allowing doctors to use that data and better treat their patients.",
                        "Even if data from just one device was available, like an Apple Watch, it could provide useful information to a doctor and enable them to quickly respond to patient needs, even if the patient isn’t physically in their office.",
                        // "In the age of the ‘Internet of Things,’ people have devices that collect tons of data about them, but not much of that data gets used to actually benefit people's health. In conjunction with the Health Tracker’ feature, we wanted to provide users a way to integrate their device/wearable data into their IHR app, allowing doctors to use that data and better treat their patients. Even if data from just one device was available, such as an Apple Watch, it could provide useful information to a doctor and enable them to quickly respond to patient needs, even if the patient isn’t physically in their office."
                    ]
                }
                carouselImages={[
                    "images/myihr/ihr-F3.jpeg",
                ]}
            />


        </motion.div>
    )
}
export default MyIHRPage;