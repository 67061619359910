import React, { useState } from "react";
import "./style.css";
// import { FaArrowLeft } from 'react-icons/fa';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { IconContext } from "react-icons";
// import { RiArrowLeftCircleFill, RiArrowRightCircleFill } from 'react-icons/ri';


function ImageCarousel(props) {

    const [currImage, setCurrImg] = useState(0);
    const carouselImages = props.carouselImages;

    const nextImage = (imgIndex) => {
        if (imgIndex + 1 === carouselImages.length) {
            setCurrImg(0);
        }
        else {
            setCurrImg(imgIndex + 1);
        }
    };

    const previousImage = (imgIndex) => {
        if (imgIndex - 1 < 0) {
            setCurrImg(carouselImages.length - 1);
        }
        else {
            setCurrImg(imgIndex - 1);
        }
    };


    return (
        <React.Fragment>
        <div className="carousel-header">

            <button className="button carousel-button is-primary" onClick={() => previousImage(currImage)}>
            <img src={"images/icons8-arrow-96-l.png"} style={{ height: "1rem", width: "1rem" }} alt="Previous Button Icon" />
                {/* <span className="icon is-small">
                    <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "1rem" }} />
                </span> */}
            </button>
            <div style={{ color: 'rgb(174, 174, 178)' }}>
                &nbsp; {currImage + 1} of {carouselImages.length} &nbsp;
            </div>
            <button className="button carousel-button is-primary" onClick={() => nextImage(currImage)}>
            <img src={"images/icons8-arrow-96-r.png"} style={{ height: "1rem", width: "1rem" }} alt="Next Button Icon" />
                {/* <span className="icon is-small">
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "1rem" }} />
                </span> */}
            </button>
        </div>

        <div className="carousel">
            <img className="carousel-inner inverted" src={carouselImages[currImage]} alt={"Carousel Image " + (currImage + 1)}/>
            {/* <div className="carousel-inner" style={{ backgroundImage: `url(${carouselImages[currImage].imgSrc})` }}>
            </div> */}
        </div>

        </React.Fragment>
    )
}

export default ImageCarousel;