import React, { useState } from 'react';
import PortfolioPanel from "./PortfolioPanel";
import AboutPanel from "./AboutPanel";
import ContactPanel from "./ContactPanel";
import DNToggle from "./DNToggle";


const HomeRightSection = (props) => {

    // Handles Nav state
    const [navState, setNavState] = useState(1);
    const toggleNav = (index) => {
        setNavState(index);
        // to ensure images are inverted properly on sub tab switch
        setTimeout(function () { props.ensureTheme(); }, 1);
    };

    // Handles Portfolio toggle state
    const [toggleState, setToggleState] = useState("UX Design");
    const toggleTab = (index) => {
        setToggleState(index);
        // to ensure images are inverted properly on sub tab switch
        setTimeout(function () { props.ensureTheme(); }, 1);
    };


    return (
        <div >

            <div className="home-right-section-nav" style={{ backgroundColor: "white" }}>
                <div className="buttons" style={{ backgroundColor: "white", borderBottom: "1px solid rgb(229,229,234)", paddingBottom: ".5rem", margin: 0 }}>
                    <button className={navState === 1 ? "button is-danger home-tab-text" : "button is-white home-tab-text"} onClick={() => toggleNav(1)}>Portfolio</button>
                    <button className={navState === 2 ? "button is-danger home-tab-text" : "button is-white home-tab-text"} onClick={() => toggleNav(2)}>About</button>
                    <button className={navState === 3 ? "button is-danger home-tab-text" : "button is-white home-tab-text"} onClick={() => toggleNav(3)} >Contact</button>
                    <div className="inverted" style={{ margin: "auto 0 auto auto" }}>
                        <DNToggle theme={props.theme} setTheme={props.setTheme} />
                    </div>
                </div>

                {navState === 1 ?
                    <div className="buttons scrolling-wrapper">
                        <button className="sub-nav-button" className={toggleState === "UX Design" ? "button is-danger is-light is-small is-rounded" : "button is-white is-small is-rounded"} onClick={() => toggleTab("UX Design")}>UX Design</button>
                        <button className="sub-nav-button" className={toggleState === "Front-End Development" ? "button is-danger is-light is-small is-rounded" : "button is-white is-small is-rounded"} onClick={() => toggleTab("Front-End Development")}>Front-End Development</button>
                        <button className="sub-nav-button" className={toggleState === "Visual Design" ? "button is-danger is-light is-small is-rounded" : "button is-white is-small is-rounded"} onClick={() => toggleTab("Visual Design")}>Visual Design</button>
                    </div>
                    : ""}
            </div>

            {navState === 1 ? <PortfolioPanel toggleState={toggleState} theme={props.theme} setTheme={props.setTheme} ensureTheme={props.ensureTheme} /> : navState === 2 ? <AboutPanel /> : <ContactPanel />}

        </div>


    )
}
export default HomeRightSection;