import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionTop from './ProjectSections/ProjSectionTop';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const DocFindPage = (props) => {
    const pageName = "DocFind";
    const projTheme = ["#8E2DE2", "linear-gradient(90deg, #4342ca 15%, rgba(255,78,78,1) 75% )"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} />
            <ProjSectionHeader
                color={'white'}
                title={"DocFind"}
                roleText={[
                    "Visual Design",
                    "Creative Direction",
                ]}
                orgText={
                    "Case Study"
                }
                toolsText={[
                    "Pen and Paper",
                    "Illustrator",
                    "Photoshop",
                ]}
                executiveSummary={
                    [
                        "A while back I needed to find a specific kind of doctor, but I had an oddly difficult time finding potential options through my insurance’s website.",
                        "It was unclear and hard to find where I could search for a specialist, let alone identify which of the presented options were best for me.",
                        "I won’t reveal the insurance company’s name, but this situation inspired me to create my own concept of a better website for finding Specialist Doctors. I named the product DocFind and was inspired by the ZocDoc app, Apple’s website, and the colors typically used in dentistry marketing materials.",
                    ]
                }
            />
            {/* <ProjSectionHeader
            color={projTheme}
            title={"DocFind"}
            description={
                [
                    "A while back I needed to find a specific kind of doctor, but I had an oddly difficult time finding potential options through my insurance’s website.",
                    "It was unclear and hard to find where I could search for a specialist, let alone identify which of the presented options were best for me.",
                    "I won’t reveal the insurance company’s name, but this situation inspired me to create my own concept of a better website for finding Specialist Doctors. I named the product DocFind and was inspired by the ZocDoc app, Apple’s website, and the colors typically used in dentistry marketing materials.",
                ]
            }
            coverImage={"images/docfind/DocFindHeaderImage.png"}
        /> */}

            <ProjSectionTop
                title={"The Design"}
                compText={
                    [
                        ''
                    ]
                }
                carouselImages={[
                    "images/docfind/DocFind-main1.png",
                    "images/docfind/DocFind-main2.png",
                    "images/docfind/DocFind-components.png",
                    "images/docfind/DocFindHeaderImage.png",
                    "images/docfind/DocFindSketches.jpg",
                ]}
            />



        </motion.div>
    )
}
export default DocFindPage;