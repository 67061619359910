import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionHalfList from './ProjectSections/ProjSectionHalfList';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const HuneebeeProjectPage = (props) => {
    const pageName = "Huneebee Project";
    const projTheme = ["#8E2DE2", "linear-gradient(270deg, #f4d49c, #b48c6c)"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} />
            <ProjSectionHeader
                color={'white'}
                title={"Huneebee Project"}
                roleText={[
                    "Visual Design",
                    "Creative Direction",
                ]}
                orgText={
                    "Case Study"
                }
                toolsText={[
                    "Pen and Paper",
                    "Illustrator",
                    "Photoshop",
                ]}
                executiveSummary={
                    [
                        "The Huneebee Project was started in 2018 by a clinical social worker and beekeeper with the vision of a world in which our youth, the community, and our environment are in a mutual and collaborative relationship.",
                        "They execute on this founding vision by bringing bee hives to community gardens and vacant green spaces, employment opportunities to local youth, and raw honey & beeswax products to you!",
                        "The Huneebee Project is a small business and social enterprise powered by youth whose proceeds go right back to funding the program.",
                        "Initially they launched as an online marketplace for honey products made from honey harvested at their garden sites, and art created by artists who embody similar values and ethics. Since then, they have not only been effective at achieving their vision by graduating multiple cohorts from their program, but have also grown as a business! They are now at the stage where they are looking for a brick-and-mortar location to serve as their headquarters and first official location.",
                        "I’m friends with the founder of The Huneebee project, so when she asked if I could help with the website, create some design artifacts, and T-shirt designs I was more than excited to get involved!",
                    ]
                }
            />
            <ProjSectionHalfList
                title={"The Designs"}
                compText={
                    [
                        "When I joined to help Huneebee Project they already had a designer deliver some Brand Identity work which they were working off of, so I referenced his work in order to ensure my work stayed on brand.",
                        "But, because I felt this was such a great organization and opportunity, I decided to create a document detailing my take and vision for The Huneebee Project brand. For this, I drew inspiration from beekeeper outfits, the positive aspects aspects about bees that people typically forget due to the stinger, and the wide range of colors present in the natural areas bees inhabit.",
                    ]
                }
                listImages={[
                    "images/huneebeeproject/HuneebeeProject-p1.png",
                    "images/huneebeeproject/HuneebeeProject-p2.png",
                    "images/huneebeeproject/HuneebeeProject-p3.png",
                    "images/huneebeeproject/HuneebeeProject-p4.png",
                    "images/huneebeeproject/HuneebeeProject-p5.png",
                    "images/huneebeeproject/HuneebeeProject-p6.png",
                    "images/huneebeeproject/HuneebeeProject-p7.png",
                    "images/huneebeeproject/HuneebeeProject-p8.png",
                    "images/huneebeeproject/HuneebeeProject-p9.png",
                    "images/huneebeeproject/HuneebeeProject-p10.png",
                    "images/huneebeeproject/HuneebeeProject-p11.png",
                    "images/huneebeeproject/HuneebeeProject-p12.png",
                    "images/huneebeeproject/HuneebeeProject-p13.png",
                    "images/huneebeeproject/HuneebeeProject-p14.png",
                    "images/huneebeeproject/HuneebeeProject-p15.png",
                    "images/huneebeeproject/HuneebeeProject-p16.png",
                    "images/huneebeeproject/HuneebeeProject-p17.png",
                    "images/huneebeeproject/HuneebeeProject-p18.png",
                    "images/huneebeeproject/HuneebeeProject-p19.png",
                    "images/huneebeeproject/HuneebeeProject-p20.png",
                    "images/huneebeeproject/HuneebeeProject-p21.png",
                    "images/huneebeeproject/HuneebeeProject-p22.png",
                    "images/huneebeeproject/HuneebeeProject-p23.png",
                    "images/huneebeeproject/HuneebeeProject-p24.png",
                    "images/huneebeeproject/HuneebeeProject-p25.png",
                    "images/huneebeeproject/HuneebeeProject-p26.png",
                    "images/huneebeeproject/HuneebeeProject-p27.png",
                    "images/huneebeeproject/HuneebeeProject-p28.png",

                ]}
            />


        </motion.div>
    )
}
export default HuneebeeProjectPage;