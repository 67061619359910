import React from 'react';
import ImageCarousel from '../ImageCarousel';


const ProjSectionLeft = (props) => {

    return (


        <div style={{ backgroundColor: props.bColor }}>
            <div className="project-page-wrapper">
                <div className="columns is-variable is-6" style={{ margin: "5rem 0" }}>
                    <div className="column is-two-fifths-desktop ">
                        <div className="header5" style={{ color: props.color }}>{props.title}</div>
                        {props.compText.map((textchunk, index) => {
                            return (
                                <div key={index} className="is-semidark" style={{ margin: "1rem 0", lineHeight: "1.5", fontWeight: "500" }}>
                                    {textchunk}
                                </div>
                            )
                        })}
                        {props.extraCode}
                    </div>
                    {props.carouselImages ?
                        <div className="column is-three-fifths-tablet">
                            <ImageCarousel carouselImages={props.carouselImages} />
                        </div>
                        : ""
                    }
                </div>
            </div>
        </div>


    )
}
export default ProjSectionLeft;