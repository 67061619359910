import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionChallengeGoals from './ProjectSections/ProjSectionChallengeGoals';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const IEDIPage = (props) => {
    const pageName = "IEDI";
    const projTheme = ["#8E2DE2", "linear-gradient(270deg, rgba(255,174,0,1), rgba(255,0,0,1))"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} />
            <ProjSectionHeader
                color={'white'}
                title={"Intelligent EDI"}
                roleText={[
                    "UX Design",
                    "UX Research",
                    "Visual Design",
                ]}
                orgText={
                    "Optum"
                }
                toolsText={[
                    "Pen & Paper",
                    "Axure",
                    "Sketch",
                    "Illustrator",
                    "Photoshop",
                    "HTML",
                    "CSS",
                    "Angular",
                ]}
                confidential={true}
                executiveSummary={
                    [
                        "IEDI is a web-based clearinghouse for healthcare providers that enables organizations to benefit from higher levels of automation and efficiency by embedding additional functionality into the EDI data stream.",
                        "It is a single solution for providers and facilities to manage the complete lifecycle of their claims from patient check-in to payment posting, eliminating multiple systems and inefficient processes, and increasing revenue cycle efficiency.",
                        "During my time on the project I worked together with another UX Designer to tackle all the UX work, before eventually leading the products UX efforts by myself.",
                        "I’ve worked with the IEDI team for over 2 years, and in that time I’ve worked I closely with the broader team to conduct user research, deliver countless designs/concepts for new features and feature improvements, and finally led the UX effort for a full front-end framework upgrade. It has been a great experience working on the IEDI team, and it is still a team I support while working at Optum today.",
                        // 'IEDI is a web-based clearinghouse for healthcare providers that enables organizations to benefit from higher levels of automation and efficiency by embedding additional functionality into the EDI data stream. It is a single solution for providers and facilities to manage the complete lifecycle of their claims from patient check-in to payment posting, eliminating multiple systems and inefficient processes, and increasing revenue cycle efficiency. During my time on the project I worked together with another UX Designer to tackle all the UX work, before eventually leading the products UX efforts by myself. I’ve worked with the IEDI team for over 2 years, and in that time I’ve worked I closely with the broader team to conduct user research, deliver countless designs/concepts for new features and feature improvements, and finally led the UX effort for a full front-end framework upgrade. It has been a great experience working on the IEDI team, and it is still a team I support while working at Optum today.'
                    ]
                }
                // carouselImages={[
                //     "images/iedi/iedi-UI1.jpeg",
                //     "images/iedi/iedi-UI2.jpeg",
                // ]}
            />

            <ProjSectionChallengeGoals
                title={"Challenge/Goals"}
                compText={
                    [
                        "The goal on IEDI was to continuously design new features for a rapidly growing product, while also ensuring that the product remained efficient, accessible, and usable as the overall scope of the product grew.",
                        "I’m not able to share all of my work on IEDI, but here is a preview of what’s public today in Q2 2021."
                    ]
                }
                carouselImages={[
                    "images/iedi/iedi-UI1.jpeg",
                    "images/iedi/iedi-UI2.jpeg",
                ]}
            />



        </motion.div>
    )
}
export default IEDIPage;