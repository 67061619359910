import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionTOC from './ProjectSections/ProjSectionTOC';
import ProjSectionHalfList from './ProjectSections/ProjSectionHalfList';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const UIToolkitPage = (props) => {
    const pageName = "UI Toolkit";
    const projTheme = ["rgb(236,120,48)"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            {/* Plain Navbar */}
            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} color={projTheme} />

            <ProjSectionHeader
                color={'white'}
                title={pageName}
                roleText={[
                    "UX Design",
                ]}
                orgText={
                    "United Healthcare / Optum"
                }
                toolsText={[
                    "Pen & Paper",
                    "Miro",
                    "Axure",
                    "Angular",
                ]}
                confidential={true}
                executiveSummary={
                    [
                        "The UI Toolkit is United Healthcare's / Optum's redesigned and reimagined company design system. The company's vision was to create a design system that utilized modern technology to support our wide variety of team needs, improve cross-functional collaboration and delivery times, and bring consistency across all the company's digital products.",
                        "As a UX group, our goal was to create an extensive data-driven library of components and assets to help both designers and developers concept, build, and deliver projects faster.",
                        "Over the course of a year I worked with a group of UX-ers to design and iterate tons of components, patterns, and layouts (each prepared for desktop, tablet, and mobile screens) for the new UI Toolkit. ",
                        "Aside from that, I was in touch with the owners and moderators of the toolkit to report issues, bugs, as well as propose improvements regarding certain functionalities/components. It was exciting to see how the library evolved and improved with each update during this short time.",
                        "The final deliverable was a completely new design system that contained 3 primary resources: a developer component library (html/css, Angular, and React), an Axure component library for UX resources, and documentation that provided guidance and background for each element."
                    ]
                }
            />
            <ProjSectionTOC
                color={projTheme}
                title={"Table of Contents"}
                htmlContent={
                    <div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            1. Background
                        </div>
                    </div>
                }
            />
            <ProjSectionHalfList
                bColor={"white"}
                color={projTheme}
                title={"1. Background"}
                compText={
                    [
                        "The UI Toolkit is United Healthcare's / Optum's new redesigned and reimagined company design system.",
                        "Being such a huge initiative, I worked with a large group of designers and developers to create this new design system and its resources. My primary role as a UX Designer was to assist in ideating and designing the various elements, components, and patterns for the toolkit.",
                        "I'm unable to show much of our work during our sessions due to confidentiality, but below is a redacted version of the showcase for the new UI Toolkit design system.",
                    ]
                }
                listImages={[
                    "images/uitoolkit/uitk-1.jpeg",
                    "images/uitoolkit/uitk-2.jpeg",
                    "images/uitoolkit/uitk-3.jpeg",
                    "images/uitoolkit/uitk-4.jpeg",
                    "images/uitoolkit/uitk-5.jpeg",
                    "images/uitoolkit/uitk-6.jpeg",
                    "images/uitoolkit/uitk-7.jpeg",
                    "images/uitoolkit/uitk-8.jpeg",
                    "images/uitoolkit/uitk-9.jpeg",
                    "images/uitoolkit/uitk-10.jpeg",
                    "images/uitoolkit/uitk-11.jpeg",
                    "images/uitoolkit/uitk-12.jpeg",
                    "images/uitoolkit/uitk-13.jpeg",
                    "images/uitoolkit/uitk-14.jpeg",
                    "images/uitoolkit/uitk-15.jpeg",
                    "images/uitoolkit/uitk-16.jpeg",
                ]}
            />


        </motion.div>
    )
}
export default UIToolkitPage;