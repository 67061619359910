import React from 'react';
// import React, { useState } from 'react';
import emailjs from 'emailjs-com';


const ContactPanel = (props) => {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('zgmail', 'port_temp', e.target, 'user_dMp0Fcd6fN35yC7XPMEao')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    };

    return (
        <div style={{ padding: "2rem .5rem" }}>

            <form className="box" style={{ marginTop: "", border: "1px solid rgb(242, 242, 247)" }} onSubmit={sendEmail} >

                <div className="header3 inverted">
                    📮
                    </div>
                <div className="header3">
                    Contact
                    </div>
                <p style={{ margin: ".75rem 0" }} className="is-semidark">
                    One of my favorite things about my job is the variety, in clients and projects (as you can see from my portfolio).
                    </p>
                <p style={{ margin: ".75rem 0" }} className="is-semidark">
                    So whether you’re a multi-national behemoth, a team that can all fit into a mid-size sedan, or just want to chat about design and tech, get in touch and tell me what you have in mind — I can’t wait to hear all about it!
                    </p>
                <p style={{ margin: ".75rem 0" }} className="is-semidark">
                    Use the form below, or email me at <span className="is-semidark" style={{ fontWeight: "600" }}>zahrlyttle@gmail.com</span>
                </p>
                <div style={{ margin: "0 0 1rem 0" }}>
                    {/* <div className="header4">Contact Form</div> */}
                    <p className="help is-danger" >
                        * All fields are required
                    </p>
                </div>

                <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                        <input className="input" type="text" name="name" required />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                        <input className="input" type="email" placeholder="e.g. alex@example.com" name="email" required />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Subject</label>
                    <div className="control">
                        <input className="input" type="text" name="subject" required />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Message</label>
                    <div className="control">
                        <textarea className="textarea" placeholder="e.g. Hello world" name="message" required></textarea>
                    </div>
                </div>

                <button className="button is-link" type='submit'>Send Message</button>
            </form>

        </div>


    )
}
export default ContactPanel;