import React from 'react';


const ProjSectionHalfList = (props) => {

    return (
        <div style={{ backgroundColor: props.bColor }}>
            <div className="project-page-wrapper">
                <div className="columns is-multiline" style={{ margin: "5rem 0" }}>
                    <div className="column is-four-fifths-desktop " >
                        <div className="header4" style={{color: props.color}}>{props.title}</div>
                        {props.compText.map((textchunk, index) => {
                            return (
                                <div key={index} className="is-semidark" style={{ margin: "1rem 0", lineHeight: "1.5", fontWeight: "500" }}>
                                    {textchunk}
                                </div>
                            )
                        })}
                    </div>

                    <div className="columns is-multiline is-variable is-8">
                        {props.listImages.map((image, index) => {
                            return (
                                <div key={index} className="column is-half-tablet">
                                    <img className="image card project-page-image inverted" style={{ width: "100%", margin: "3rem 0" }} src={image} alt={props.title + " Project Featured Image"} />
                                </div>
                            )
                        })
                        }
                    </div>

                </div>
            </div>
        </div>


    )
}
export default ProjSectionHalfList;