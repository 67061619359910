import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import styled from 'styled-components';
import { Link } from 'react-router-dom'
import DNToggle from './DNToggle';


const Navbar = (props) => {

    return (
        <div style={{ backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 100, borderBottom: "1px solid rgb(242,242,247)" }}>
                <div className="project-navbar-wrapper">
                    <nav className={"navbar navbar-wrapper "} style={{ padding: "1rem 0", backgroundColor: "transparent" }}>
                        <Link to="/" className="z-navbar-link is-dark" onClick={() => setTimeout(function () { props.ensureTheme(); }, 1)}>
                            <span className="icon">
                                <img src={"images/icons8-arrow-96-l-dark.png"} style={{ height: "1rem", width: "1rem" }} alt="Back Button Icon" />
                            </span>
                            zahr.io
                        </Link>
                        <div >
                            <strong style={{padding: 'none'}} >
                                {props.pageName}
                            </strong>
                        </div>
                        <div className="inverted">
                            <DNToggle theme={props.theme} setTheme={props.setTheme} />
                        </div>
                    </nav>
                </div>
            </div>
    );
}

export default Navbar;
