import React from 'react';
import ImageCarousel from '../ImageCarousel';


const ProjSectionTop = (props) => {

    return (
        <div style={{ backgroundColor: props.bColor }}>
            <div className="project-page-wrapper">
                <div className="columns is-multiline" style={{ margin: "5rem 0" }}>
                    <div className="column is-three-quarters-desktop " >
                        <div className="header4" style={{ color: props.color }} >{props.title}</div>
                        {props.compText.map((textchunk, index) => {
                            return (
                                <div key={index} style={{ margin: "1rem 0" }}>
                                    {textchunk}
                                </div>
                            )
                        })}
                        {props.extraCode}
                    </div>
                    {props.carouselImages ?
                        <div className="column is-full">
                            <ImageCarousel carouselImages={props.carouselImages} />
                        </div>
                        : ""
                    }
                </div>
            </div>
        </div>


    )
}
export default ProjSectionTop;