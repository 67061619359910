import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionHalfList from './ProjectSections/ProjSectionHalfList';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const GroceryDeliveryAppPage = (props) => {
    const pageName = "Grocery Delivery App";
    // const projTheme = ["#8E2DE2", "linear-gradient(30deg, #dad4ec, #93a5cf)"];
    const projTheme = ["#8E2DE2", "linear-gradient(270deg, #fda34b 15%, #ff7882 35%, #c8699e 52%, #7046aa 65%, #0c1db8 87%, #020f85 100%)"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} />
            <ProjSectionHeader
                color={'white'}
                title={"Grocery Delivery App"}
                roleText={[
                    "Visual Design",
                    "Creative Direction",
                ]}
                orgText={
                    "Case Study"
                }
                toolsText={[
                    "Pen and Paper",
                    "Illustrator",
                    "Photoshop",
                ]}
                executiveSummary={
                    [
                        "With the rise of grocery delivery services like AmazonFresh and Instacart, I’ve noticed how much more often I’ve been targeted with their emails and ads.",
                        "After being bombarded with so many ads from these companies, all of the different logos and branding started to just blend together to me. None of them really stick out or seem different.",
                        "So, I decided to create my own concepts of what a new grocery delivery app/store's branding would look like. I drew inspiration for these concepts from the bright colors of fresh fruit, and the feeling you get when you’re hungry and are about to finally enjoy a plate of fresh fruit or homemade food.",
                    ]
                }
            />

            <ProjSectionHalfList
                title={"The Designs"}
                compText={
                    [
                        ''
                    ]
                }
                listImages={[
                    "images/grocerydeliveryapp/GroceryDeliveryApp-Grown.png",
                    "images/grocerydeliveryapp/GroceryDeliveryApp-blooberri.png",
                    "images/grocerydeliveryapp/GroceryDeliveryApp-froot.png",
                    "images/grocerydeliveryapp/GroceryDeliveryApp-Growph.png",
                    "images/grocerydeliveryapp/GroceryDeliveryApp-Mang.png",
                    "images/grocerydeliveryapp/GroceryDeliveryApp-DewBox1.png",
                    "images/grocerydeliveryapp/GroceryDeliveryApp-Vida.png",
                    "images/grocerydeliveryapp/GroceryDeliveryApp-DewDrop.png",
                    "images/grocerydeliveryapp/GroceryDeliveryApp-DewBox2.png",
                ]}
            />



        </motion.div>
    )
}
export default GroceryDeliveryAppPage;