import React from 'react';
import { Link } from 'react-router-dom';
import { ProjectData } from "../helpers/ProjectData";
import Tilt from 'react-parallax-tilt';


const PortfolioPanel = (props) => {

    const filteredProjects = ProjectData.filter((project) => {
        return project.category.includes(props.toggleState);
    });


    return (
        <div style={{ padding: "2rem .5rem" }}>

            {/* Project Cards */}
            <div className="columns is-multiline" >
                {filteredProjects.map((project) => {
                    return (
                        <div key={project.title} className="column is-one-third" >
                            <Link to={project.route} onClick={() => setTimeout(function() { props.ensureTheme(); }, 1)}>
                            <Tilt tiltMaxAngleX={"15"} tiltMaxAngleY={"15"} >
                                <div className="home-project" >
                                    <img className="image card home-project-image inverted" style={{ width: "100%" }} src={project.thumbnailSrc} alt={project.title + " Project Thumbnail"} />

                                    <p className="home-project-info">
                                        <span className="tag is-danger is-light is-rounded" style={{margin: '0 0 .5rem 0'}}>{project.category}</span>
                                        <br />
                                        <strong className="home-project-title">{project.title}</strong>
                                        <br />
                                        {project.description}
                                    </p>
                                </div>
                                </Tilt>
                            </Link>

                        </div>

                    )
                })}
            </div>





        </div>

    )
}
export default PortfolioPanel;