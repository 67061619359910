import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const EngineeredFitnessPage = (props) => {
    const pageName = "Engineered Fitness";
    const projTheme = ["#8E2DE2", "linear-gradient(270deg, #DEC39B, #a87c64)"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} />
            <ProjSectionHeader
                color={'white'}
                title={"Engineered Fitness"}
                roleText={[
                    "UX Design",
                    "Assisted UX Research",
                    "Visual Design",
                    "Creative Direction",
                ]}
                orgText={
                    "EngineeredFitness"
                }
                toolsText={[
                    "Pen & Paper",
                    "Illustrator",
                    "HTML",
                    "CSS",
                    "Javascript",
                    "Angular",
                    "Firebase",
                ]}
                executiveSummary={
                    [
                        "The founders of EngineeredFitness are close friends of mine, so when they decided they needed a website to better market themselves and sell their products, they reached out to me!",
                        "My role was to understand what they wanted to accomplish with the company website, create and iterate the design, and finally build and deploy the website with all the features they needed.",
                        // 'The founders of EngineeredFitness are close friends of mine, so when they decided they needed a website to better market themselves and sell their products, they reached out to me! My role was to understand what they wanted to accomplish with the company website, create and iterate the design, and finally build and deploy the website with all the features they needed.'
                    ]
                }
                carouselImages={[
                    "images/engineeredfitness/EngineeredFitness-preview.png",
                ]}
                siteLink={
                    "https://engineeredfit.us/"
                }
            />
            {/* <ProjSectionHeader
                color={projTheme}
                title={pageName}
                description={
                    [
                        "EngineeredFitness is a company and community focused on providing people quality fitness and health resources backed by science to help you reach your fitness goals.",
                        "With many locations being closed due to covid in 2020-2021, people don't have access to the places and things they used to stay in shape like gyms. EngineeredFitness is trying to bridge that gap by creating and providing resources that people can use from the comfort of their own home."
                    ]
                }
                coverImage={"images/engineeredfitness/EngineeredFitnessCover.jpg"}
            /> */}





        </motion.div>
    )
}
export default EngineeredFitnessPage;