import React from 'react';
import ImageCarousel from '../ImageCarousel';


const ProjSectionChallengeGoals = (props) => {

    return (
        <div style={{ backgroundColor: props.color }}>
            <div className="project-page-wrapper">
                <div className="columns is-variable is-4 is-multiline" style={{ margin: "5rem 0" }}>
                    <div className="column is-four-fifths-desktop " >
                        <div className="header3">{props.title}</div>
                        {props.compText.map((textchunk, index) => {
                            return (
                                <div key={index} style={{ margin: "1rem 0" }}>
                                    {textchunk}
                                </div>
                            )
                        })}
                    </div>
                    {props.carouselImages ?
                        <div className="column is-full">
                            <ImageCarousel carouselImages={props.carouselImages} />
                        </div>
                        : ""
                    }
                </div>
            </div>
        </div>


    )
}
export default ProjSectionChallengeGoals;