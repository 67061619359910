import React from 'react';
// import React, { useState } from 'react';

const AboutPanel = (props) => {


    return (
        <div style={{ padding: "2rem .5rem" }}>

            <div className="box" style={{ marginTop: "", border: "1px solid rgb(242, 242, 247)" }} >

                <div className="header3 inverted">
                    🧑🏻‍💻
                </div>
                <div className="header3">
                    About
                </div>
                <p style={{ margin: ".75rem 0", fontWeight: "600" }} className="is-semidark">
                    All work. All play.
                </p>
                <p style={{ margin: ".75rem 0" }} className="is-semidark">
                    First off, thanks for making it here!
                </p>
                <p style={{ margin: ".75rem 0" }} className="is-semidark">
                    I am an artist, designer, and techie passionate about solving problems. Having a background in both software engineering and user experience, I enjoy using my skills to bring ideas to life in the form of valuable features and new products.
                </p>
                <p style={{ margin: ".75rem 0" }} className="is-semidark">
                    Right now I'm at <a href="https://www.storyblocks.com/" target="_blank"><u style={{ fontWeight: "500" }}>Storyblocks</u></a>, working across a few different initiatives including our Design System, Enterprise Growth, and overall Product Growth. In the past I've led design on product teams, mentored designers, and worked with leadership teams to craft visions and set product strategy.
                </p>
                <p style={{ margin: ".75rem 0" }} className="is-semidark">
                    In my free time, I like exploring with my dogs, playing video games, and making beats.
                </p>

                <div className="header4" style={{ margin: "3rem 0 0 0" }}>
                    My Values
            </div>
                <div className="header5" style={{ margin: "1rem 0 .5rem 0", fontWeight: "600" }}>
                    🔥 Passion is essential.
            </div>
                <p style={{ margin: ".25rem 0" }} className="is-semidark">
                    Focus your energy into something that brings you happiness and you believe in. If you don’t enjoy what you’re doing - pivot. Ever since I was a kid I've been inspired by people who love what they do. Life is short, so why waste it?
                    </p>
                <div className="header5" style={{ margin: "1rem 0 .5rem 0", fontWeight: "600" }}>
                    🤓 Forever learning.
            </div>
                <p style={{ margin: ".25rem 0" }} className="is-semidark">
                    Humans are constant works in progress. We need challenges and feedback in order to grow. That's why I'm always exploring ways to challenge myself and learn something new. Recently I've been focused on tackling coding problems, reading about startups and product, and taking on new client/personal projects.
            </p>
                <div className="header5" style={{ margin: "1rem 0 .5rem 0", fontWeight: "600" }}>
                    🙌 Always humble.
            </div>
                <p style={{ margin: ".25rem 0" }} className="is-semidark">
                    Treat others with the same respect you would yourself. Regardless if someone is CEO of a company, homeless, or an average person on the street, treat everyone with respect and kindness. Everyone is facing their own unique battles everyday, so stay true to yourself and to others.
                    </p>
            </div>
        </div>


    )
}
export default AboutPanel;