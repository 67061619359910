import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
    backgroundColor: "#fff",
    fontColor: "#000",
}
export const darkTheme = {
    backgroundColor: "#000",
    fontColor: "#fff",
}

// Theme classes
export const GlobalStyles = createGlobalStyle`

    // .theme-background {
    //     background-color: ${(props) => props.theme.backgroundColor};
    //     color: ${(props) => props.theme.fontColor};
    // }
    
`;