import React from 'react';
import ImageCarousel from '../ImageCarousel';


const ProjSectionHeader = (props) => {

    return (

        <div style={{ backgroundColor: props.color, marginBottom: '2rem' }}>

            <div className="project-page-wrapper">
                <div className="columns is-variable" style={{ margin: "3rem 0" }}>
                    <div className="column is-one-third-desktop ">
                        <span className="tag is-light is-danger is-medium is-rounded">UX Design</span>
                        <div className="header2" style={{ margin: "1rem 0" }}> {props.title} </div>
                        <p className="project-header-subtitle">
                            MY ROLE
                        </p>
                        {/* <span className="tag is-light is-medium" style={{ fontWeight: "600" }}>MY ROLE</span> */}
                        <div style={{ marginTop: "1rem" }}>
                            {props.roleText.map((item, index) => {
                                return (
                                    <p className="project-header-subtext is-semidark" key={index}>
                                        {item}
                                    </p>
                                )
                            })}
                        </div>
                        <p className="project-header-subtitle">
                            COMPANY
                        </p>
                        <p className="project-header-subtext is-semidark" >
                            {props.orgText}
                        </p>
                        <p className="project-header-subtitle">
                            TOOLS
                        </p>
                        <div className="columns is-multiline is-mobile" style={{ marginTop: ".5rem" }}>
                            {props.toolsText.map((item, index) => {
                                return (
                                    <div key={index} className="column is-narrow">
                                        <span className="tag is-link is-light is-medium">{item}</span>
                                    </div>
                                )
                            })}
                        </div>
                        {props.confidential ?
                            <div class="card" style={{ marginTop: "3rem"}}>
                                <div class="card-content">
                                    <div class="content">
                                        <p className=" is-semidark" >
                                            <span style={{color: "rgb(255,149,10)", fontWeight: "600"}}>  Note: </span>
                                            <br></br>
                                            Due to confidentiality I cannot publicly share certain details of this project.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            : ""
                        }
                        {props.extraCode}
                        {props.siteLink ?
                            <div>
                                <p className="project-header-subtitle">
                                    LINK
                                </p>
                                <div className="buttons" style={{ margin: "1rem 0" }}>
                                    <a href={props.siteLink} target="_blank" rel="noreferrer">
                                        <button className="button is-link">
                                            View Live Project
                                        </button>
                                    </a>
                                </div>
                            </div>
                            : ""
                        }
                        {props.testDiv}

                    </div>
                    <div className="column">
                    </div>
                    <div className="column is-half-tablet">
                        <p className="project-header-subtitle">
                            EXECUTIVE SUMMARY
                        </p>
                        {props.executiveSummary.map((textchunk, index) => {
                            return (
                                <div key={index} className="is-semidark" style={{ margin: "1rem 0", fontWeight: "400", fontSize: "1rem", lineHeight: "1.5" }}>
                                    {textchunk}
                                </div>
                            )
                        })}
                    </div>
                </div>


                <div style={{ marginTop: "8rem" }}>
                    {props.carouselImages ?
                        <div className="column is-full">
                            <ImageCarousel carouselImages={props.carouselImages} />
                        </div>
                        : ""
                    }
                </div>
            </div>
        </div>


    )
}
export default ProjSectionHeader;