import React, { useState } from 'react';
import './App.css';
import 'bulma/css/bulma.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyles } from "./themes.js";
// COMPONENTS
import ScrollToTop from './components/ScrollToTop';
import HomePage from './components/HomePage';
import MeridianPage from './components/MeridianPage';
import IOS14conceptPage from './components/IOS14conceptPage';
import GPFHPage from './components/GPFHPage';
import MyIHRPage from './components/MyIHRPage';
import IEDIPage from './components/IEDIPage';
import EngineeredFitnessPage from './components/EngineeredFitnessPage';
import UIToolkitPage from './components/UIToolkitPage';
import WhayPage from './components/WhayPage';
import DocFindPage from './components/DocFindPage';
import GroceryDeliveryAppPage from './components/GroceryDeliveryAppPage';
import HuneebeeProjectPage from './components/HuneebeeProjectPage';
import Footer from './components/Footer';

import { AnimatePresence } from 'framer-motion';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
library.add(fab, faCheckSquare, faCoffee);


// Themes
const themes = {
  light: lightTheme,
  dark: darkTheme
}
// const StyledDiv = styled.div`
//   background: ${(props) => props.theme.backgroundColor};
// `;


function App() {
  const [theme, setTheme] = useState("light");
  // const toggleTheme = () => {
  //   theme === 'light' ? setTheme('dark') : setTheme('light');
  // };

  const ensureTheme = () => {
    // Change theme using ThemeProvider
    if (theme === 'light') {
      document.documentElement.classList.remove("dark-mode");
      document.querySelectorAll('.inverted').forEach((result) => {
        result.classList.remove('invert');
      })
    }
    else if (theme === 'dark') {
      document.documentElement.classList.add("dark-mode");
      document.querySelectorAll('.inverted').forEach((result) => {
        result.classList.add('invert');
      })
    }
  };

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyles />
      {/* <StyledDiv> */}
      <Router>
        <ScrollToTop />
        <div className="App">
          {/* <Navbar theme={theme} setTheme={setTheme} /> */}
          <AnimatePresence>
            <Switch>
              <Route path="/" exact>
                <HomePage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/meridian">
                <MeridianPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/gpfulfillmenthub">
                <GPFHPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
              </Route>
              <Route path="/ios14concept">
                <IOS14conceptPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
              </Route>
              <Route path="/uitoolkit">
                <UIToolkitPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/whay">
                <WhayPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/myihr">
                <MyIHRPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/iedi">
                <IEDIPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/docfind">
                <DocFindPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/grocerydeliveryapp">
                <GroceryDeliveryAppPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/huneebeeproject">
                <HuneebeeProjectPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
              <Route path="/engineeredfitness">
                <EngineeredFitnessPage theme={theme} setTheme={setTheme} ensureTheme={ensureTheme} />
                <Footer />
              </Route>
            </Switch>
          </AnimatePresence>
        </div>
      </Router>
      {/* </StyledDiv> */}
    </ThemeProvider>

  );
}

export default App;
