import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionTOC from './ProjectSections/ProjSectionTOC';
import ProjSectionSubSec from './ProjectSections/ProjSectionSubSec';
import ProjSectionLeft from './ProjectSections/ProjSectionLeft';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const GPFHPage = (props) => {
    const pageName = "GP Fulfillment Hub";
    const projTheme = ["rgba(0,33,122,255)"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            {/* Plain Navbar */}
            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} color={projTheme} />

            <ProjSectionHeader
                color={'white'}
                title={pageName}
                roleText={[
                    "UX Design",
                    "UX Research",
                    "UX Strategy",
                ]}
                orgText={
                    "United Healthcare"
                }
                toolsText={[
                    "Pen & Paper",
                    "Axure",
                    "Illustrator",
                    "Photoshop",
                    "HTML",
                    "CSS",
                    "Angular",
                ]}
                executiveSummary={
                    [
                        "GP Fulfillment Hub (GPFH) is an application that is used to manage the fulfillment and distribution of all healthcare related mailings for specific customer groups. Imagine ID Cards, Welcome Kits, and any physical healthcare items someone may need.",
                        "Over the years, GPFH's design has become stagnant and bloated with an overabundance of features and no UX strategy. The current interface is noisy, cumbersome, and lacks clarity in its intent.",
                        "The GPFH team recognized the need for a complete rebuild, so they started an initiative to redesign the application to streamline the user experience, improve usability, and implement the company's new design system.",
                        "I joined the team to spearhead this redesign as their first ever UX resource. During that time I led the UX work on GPFH, and closely collaborated with a cross-discipline team to research, design, iterate, and roll-out an entirely new GPFH user experience and application.",
                        "We had a 1 year timeline to design and successfully launch the new GPFH application. In that time we were able to deliver exactly that with lots of positive feedback from stakeholders and user metrics to prove it!",
                    ]
                }
                confidential={true}
                carouselImages={[
                    "images/gpfh/gpfh-F1.jpeg",
                    "images/gpfh/gpfh-F2.jpeg",
                    "images/gpfh/gpfh-F3.jpeg",
                    "images/gpfh/gpfh-F4.jpeg",
                ]}
            />
            <ProjSectionTOC
                color={projTheme}
                title={"Table of Contents"}
                htmlContent={
                    <div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            1. Background
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            2. Design Process
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Empathize
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Define
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Ideate
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Prototype
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Test
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            3. Implementation and Handoff
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            4. Takeaways
                        </div>
                    </div>
                }
            />
            <ProjSectionSubSec
                bColor={"white"}
                color={projTheme}
                title={"1. Background"}
                compText={
                    [
                        "GP Fulfillment Hub is an application that is used to manage the fulfillment and distribution of healthcare related mailings for specific customer groups.",
                        "The GPFH team recognized the need to completely rebuild the application, so they started an initiative to redesign GPFH as well. The main goals being to streamline the user experience, improve usability, and implement the company's new design system.",
                        "I joined the team to spearhead this redesign as their first ever UX resource. In that time, I led the UX work on GPFH, and closely collaborated with a cross-discipline team to research, design, iterate, and roll-out an entirely new GPFH user experience and application.",
                    ]
                }
                carouselImages={[
                    "images/gpfh/gpfh-1a.jpeg",
                    "images/gpfh/gpfh-1b.jpeg",
                    "images/gpfh/gpfh-1c.jpeg",
                    "images/gpfh/gpfh-1d.jpeg",
                ]}
            />
            <ProjSectionSubSec
                bColor={"white"}
                color={projTheme}
                title={"2. Design Process"}
                compText={
                    [
                        "After nailing down our Problem, Goals, and receiving a crash course on the secrets of how GPFH works and it's architecture, I began working to create and define the UX process for the team.",
                        "My focus was to begin crafting the new GPFH user experience, but I also aimed to embed a data-driven UX Design process onto the team so that they could more consistently and continuously improve GPFH.",
                        "The design process I implemented during this project is broken down into the following phases:",
                    ]
                }
                bulletText={
                    <div style={{ margin: "2rem 1rem"}}>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Empathize
                            </div>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Define
                            </div>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Ideate
                            </div>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Prototype
                            </div>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Test
                            </div>
                    </div>
                }
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2a. Design Process - Empathize"}
                compText={
                    [
                        "We had a tight timeline to deliver a finalized MVP design/wireframe.",
                        "To ensure we understood our users as best we could, I conducted user interviews to:",
                        "- Understand current user workflows and usage",
                        "- Validate known user issues and pain points",
                        "- Uncover new user issues and pain points",
                        "These interviews validated much of the user feedback I gathered beforehand, but also helped uncover new paint points and reasons for user frustration/enjoyment of the old UI.",
                    ]
                }
                carouselImages={[
                    "images/gpfh/gpfh-2a.jpeg",
                    "images/gpfh/gpfh-2b.jpeg",
                    "images/gpfh/gpfh-2c.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2b. Design Process - Define"}
                compText={
                    [
                        "After compiling and analyzing the user interview findings I was able to define two key artifacts for the team.",
                        "- Initial User Personas",
                        "- Initial Site Map",
                        "These were important because they helped the team:",
                        "- Understand exactly 'who' we were building for",
                        "- Create buy in for an initial user workflow",
                    ]
                }
                carouselImages={[
                    "images/gpfh/gpfh-2d.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2c. Design Process - Ideate"}
                compText={
                    [
                        "Now it was time to start crafting and ideating the new GPFH's MVP design.",
                        "I worked very closely with Users to experiment with Different Design Patterns/Layouts, Color Patterns, and Workflows.",
                        "I also regularly met with the Business and Dev teams to ensure the concepts were in scope and technically feasible.",
                    ]
                }
                carouselImages={[
                    "images/gpfh/gpfh-2e.jpeg",
              ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2d. Design Process - Prototype"}
                compText={
                    [
                        "Although it was planned that the new GPFH was not going to be responsive (only desktop), I knew that company provided desktop machines screen sizes varied and that we needed to account for that.",
                        "After a few weeks of sharing and rapidly iterating concepts, I synthesized a final MVP design that received stakeholder approval.",
                        "I then took the wireframe from mid to high fidelity; fleshing out all interactions and adding specific use case logic."
                    ]
                }
                carouselImages={[
                    "images/gpfh/gpfh-2f.jpeg",
                    "images/gpfh/gpfh-2g.jpeg",
                    "images/gpfh/gpfh-2h.jpeg",
                    "images/gpfh/gpfh-2i.jpeg",
                    "images/gpfh/gpfh-2j.jpeg", 
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2e. Design Process - Test"}
                compText={
                    [
                        "Originally, we had only planned to conduct User Acceptance Testing before launch.",
                        "Fortunately we were ahead of schedule development wise, so I proposed a Usability Study as a final verification/catch-all and to gather baseline metrics before our hard launch.",
                        "We ended up conducting both the User Acceptance Testing and Usability Study."
                    ]
                }
                carouselImages={[
                    "images/gpfh/gpfh-2k.jpeg",
                    "images/gpfh/gpfh-2l.jpeg",
                    "images/gpfh/gpfh-2m.jpeg",
                    "images/gpfh/gpfh-2n.jpeg",
                    "images/gpfh/gpfh-2o.jpeg", 
                ]}
            />
            <ProjSectionSubSec
                bColor={"white"}
                color={projTheme}
                title={"3. Implementation and Handoff"}
                compText={
                    [
                        "After studying the users, experimenting and iterating concepts, and constantly updating the new GPFH design based on user testing we were able to deliver an amazing new GPFH user experience by launch date.",
                        "Once we had stakeholder approval of the MVP design, I began to work very close with the Dev team for implementation and development up until launch. The core design remained largely unchanged, but during development there were minor UI tweaks that we made along the way due to our architecture and user feedback."
                    ]
                }
                carouselImages={[
                    "images/gpfh/gpfh-3a.jpeg",
                    "images/gpfh/gpfh-3b.jpeg",
                    "images/gpfh/gpfh-3c.jpeg",
                    "images/gpfh/gpfh-3d.jpeg",
                    "images/gpfh/gpfh-3e.jpeg",
                    "images/gpfh/gpfh-3f.jpeg",
                    "images/gpfh/gpfh-3g.jpeg",
                ]}
            />
            <ProjSectionSubSec
                bColor={"white"}
                color={projTheme}
                title={"4. Takeaways"}
                compText={
                    [
                        "Working on the GP Fulfillment Hub team was a great experience. This redesign was very successful as it was received and tested well with users, and ultimatley saved the business lots of capital.",
                    ]
                }
                carouselImages={[
                    "images/gpfh/gpfh-4a.jpeg",
                    "images/gpfh/gpfh-F1.jpeg",
                    "images/gpfh/gpfh-F2.jpeg",
                    "images/gpfh/gpfh-F3.jpeg",
                    "images/gpfh/gpfh-F4.jpeg",
                ]}
            />



        </motion.div>
    )
}
export default GPFHPage;