import React from 'react';
// import styled from 'styled-components';
import HomeRightSection from "./HomeRightSection";
import Resume from "../data/Zahr'sResumeUX-0621.pdf";
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';
import Tilt from 'react-parallax-tilt';


const HomePage = (props) => {

    return (
        <motion.div
        initial="out"
        animate="in"
        exit="out"
        variants={ animationFade }
        transition={ transition }
        >
        <div style={{marginBottom: '6rem'}}>
        <div className="sticky-topbar"></div>

        <div className="page-wrapper ">
            <div className="columns is-multiline is-variable is-4">

                {/* Left Section */}
                <div className="column is-two-fifths home-left-area">
                    <div className="home-left-section">
                    <Tilt tiltMaxAngleX={"5"} tiltMaxAngleY={"5"} gyroscope={true}>
                        <figure className="image is-64x64" >
                            <img src={"images/ZahrLogo-BFilled.png"} alt="Zahr's Logo" />
                        </figure>
                        <div className="title-header" style={{ marginTop: "2rem" }}><span className="is-rainbow">UX Designer</span> <span className="is-outline">&</span> <br />Front-End Developer.</div>
                        <div className="subtext1" style={{ fontWeight: 600 }}><span className="inverted">👋&nbsp; </span> Hi, I'm Zahr.</div>
                        <img src={"images/hometext.png"} alt="Zahr's Background" style={{margin: "1rem 0"}} />
                        {/* <div className="subtext1"> I help organizations design, build, and launch thoughtful digital products and experiences.</div> */}
                        </Tilt>

                        <nav className="level is-mobile" style={{ marginTop: "1rem" }}>
                            <div className="level-left">
                                <div className="level-item">
                                    <figure className="image is-64x64 card" style={{ borderRadius: "2rem", border: "1px solid rgb(242,242,247)" }} >
                                        <img src={"images/zemoji-white.png"} alt="Zahr's Apple Emoji Profile" className="zemoji inverted" />
                                    </figure>
                                </div>
                                <div className="level-item">
                                    <button className="button is-light is-rounded inverted" >
                                        <a href={Resume} style={{color: "#F14668"}} download>Resume</a>
                                        {/* <a href={Resume} download>Resume</a> */}
                                    </button>
                                    {/* <div style={{fontSize: "1.25rem", fontWeight: 900}}>Hi, I'm Zahr. &nbsp; <span className="inverted">👋</span></div> */}
                                </div>
                            </div>
                        </nav>
                        <img src={"images/zw-easteregg1.png"} alt="dark-mode easter egg" style={{margin: "2rem 0 0 0"}} className="inverted"/>


                    </div>
                </div>

                {/* Right Section */}
                <div className="column is-three-fifths home-right-area">
                    <HomeRightSection theme={props.theme} setTheme={props.setTheme} ensureTheme={props.ensureTheme} />
                </div>

            </div>
        </div>
        </div>
        </motion.div>
    )
}
export default HomePage;