import React from 'react';
import Navbar from './Navbar';
import ProjSectionHeader from './ProjectSections/ProjSectionHeader';
import ProjSectionTOC from './ProjectSections/ProjSectionTOC';
import ProjSectionSubSec from './ProjectSections/ProjSectionSubSec';
import ProjSectionLeft from './ProjectSections/ProjSectionLeft';
import { motion } from 'framer-motion';
import { animationFade, transition } from '../animations';


const MeridianPage = (props) => {
    const pageName = "Meridian";
    const projTheme = ["rgb(115, 77, 255)"];

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animationFade}
            transition={transition}
        >

            {/* Plain Navbar */}
            <Navbar theme={props.theme} setTheme={props.setTheme} pageName={pageName} ensureTheme={props.ensureTheme} color={projTheme} />

            <ProjSectionHeader
                color={'white'}
                title={"Meridian"}
                roleText={[
                    "UX Design",
                    "Assisted UX Research",
                    "Visual Design",
                    "Creative Direction",
                ]}
                orgText={
                    "Optum"
                }
                toolsText={[
                    "Pen & Paper",
                    "Axure",
                    "Illustrator",
                    "Photoshop",
                    "HTML",
                    "CSS",
                    "Angular",
                ]}
                executiveSummary={
                    [
                        "When in need of healthcare services, people often have a difficult time making the best financial decisions when choosing a provider. There are either no tools available that provide users information regarding their healthcare options, or the tools that are available are unreliable and cumbersome to use.",
                        "Considering that the average American is unable to pay an unexpected $400 healthcare bill at any given time without going into debt, it is incredibly important that healthcare options be available and affordable.",
                        "Meridian is a healthcare service cost estimator tool aimed at helping users make the best financial and medical decisions for their unique healthcare needs.",
                        "I joined the team at a very early stage as a UX Designer to help deliver a cross-platform design for the MVP. During my time on the project I collaborated with a UX Researcher to spearhead all the project's UX work.",
                        "During this ~5 month engagement, I worked closely with the broader team to:",
                        "- Conduct user and competitor research",
                        "- Deliver an initial MVP wireframe which was iterated upon as we conducted multiple rounds of user testing",
                        "- Provide a detailed UX style guide for the development team to use after the engagement",
                        "I'm proud to say I thought this project was a big success because we delivered on all of our initial commitments, the application performed very well with potential users and the larger business stakeholders, and lastly because it was blast working with the larger team! :)"
                        // 'Meridian is a healthcare service cost estimator tool aimed at helping users make the best financial and medical decisions for their unique healthcare wants and needs. After some light visual design work was done to pitch the product idea and receive approval, I joined the team as a UX Designer to help deliver a cross-platform design for the MVP. During my time on the project I worked together with a UX Researcher to spearhead all the UX work. This engagement lasted ~5 months, and in that time worked I closely with the broader team to conduct background/competitor research, deliver an initial MVP wireframe which was iterated upon as we conducted multiple rounds of user testing, and finally provide a detailed UX style guide for the team to use after our engagement completed.',
                    ]
                }
                confidential={true}
                carouselImages={[
                    "images/meridian/meridian-F-1.jpeg",
                    "images/meridian/meridian-F-2.jpeg",
                    "images/meridian/meridian-F-3.jpeg",
                ]}
            />
            <ProjSectionTOC
                color={projTheme}
                title={"Table of Contents"}
                htmlContent={
                    <div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            1. Background
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            2. Design Process
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Empathize
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Define
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Ideate
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Prototype
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • Test
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            3. Implementation and Handoff
                        </div>
                        <div className="is-white" style={{ margin: "1.25rem 0", fontSize: "1.25rem", fontWeight: "600" }}>
                            4. Takeaways
                        </div>
                    </div>
                }
            />
            <ProjSectionSubSec
                bColor={"white"}
                color={projTheme}
                title={"1. Background"}
                compText={
                    [
                        "The Meridian project was an initiative aimed at developing a healthcare service cost estimator tool to help users make better financial and medical decisions for their unique healthcare needs.",
                        "Essentially, Meridian was to be a new product that gave users more insight about their healthcare options by providing accurate estimates and enabling users to 'shop' around for healthcare services.",
                        "I joined the team at a very early stage, and as a UX Designer I collaborated closely with a UX Researcher to help define the scope of our product and deliver a cross-platform design for the MVP.",
                        "During onboarding we worked with the team to define two things in order to help us get to work. The Problem we were trying to solve, and UX Goals for the project.",
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-1-1.jpeg",
                    "images/meridian/meridian-1-2.jpeg",
                ]}
            />
            <ProjSectionSubSec
                bColor={"white"}
                color={projTheme}
                title={"2. Design Process"}
                compText={
                    [
                        "After identifying our Problem and Goal I began working to create and define the UX process for our team.",
                        "My focus was to take the team from the high level idea to stage, to having an embedded data-driven UX Design process and a tested MVP design ready for developers to pick up.",
                        "The design process we implemented during this engagement is broken down into the following phases:",
                    ]
                }
                bulletText={
                    <div style={{ margin: "2rem 1rem"}}>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Empathize
                            </div>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Define
                            </div>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Ideate
                            </div>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Prototype
                            </div>
                        <div style={{ margin: "1.25rem 0", fontSize: "1rem", fontWeight: "600", color: projTheme }}>
                            • Test
                            </div>
                    </div>
                }
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2a. Design Process - Empathize"}
                compText={
                    [
                        "Considering our timeline, we had limited time to truly empathize our users.",
                        "To ensure we understood our potential users and competitive space, we conducted:",
                        "- Consumer/User Research",
                        "- Competitive Analysis",
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-2-1a.jpeg",
                    "images/meridian/meridian-2-1b.jpeg",
                    "images/meridian/meridian-2-1c.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2b. Design Process - Define"}
                compText={
                    [
                        "After analyzing and combining our background research, Consumer/User Research, and Competitive Analysis we were able to define two key artifacts. We created: ",
                        "- Initial User Personas",
                        "- Initial Site Map",
                        "One thing worth noting is why we have two user personas. We discovered that our older audience had a general distrust of healthcare professionals/tools whereas the younger audience had more of a neutral outlook.",
                        "This affected how willing they were to share personal information with healthcare providers/tools.",
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-2-2a.jpeg",
                    "images/meridian/meridian-2-2b.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2c. Design Process - Ideate"}
                compText={
                    [
                        "Now it was time to start creating and ideating Meridian's MVP design.",
                        "To do this I worked very closely with Users to experiment with Different Design Patterns/Layouts, Color Patterns, and Workflows.",
                        "I also worked closely with the Business and Dev teams to ensure the concepts were in scope and technically feasible.",
                        "Ultimately, we landed on an initial design that was close to what we initially pitched and received stakeholder buy-in for."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-2-3a.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2d. Design Process - Prototype"}
                compText={
                    [
                        "One of the requirements for the application was that it needed to be responsive (mobile/tablet/desktop).",
                        "Our research indicated that most users would access Meridian using larger screen devices such as desktops/laptops (at doctors office / at home), so we decided to first build out the desktop version of our prototype.",
                        "This goes against typical responsive development guidance, but we wanted to first create AND test the desktop version before creating the tablet and mobile versions because of the primary use case and our timeline."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-2-4a.jpeg",
                    "images/meridian/meridian-2-4b.jpeg",
                    "images/meridian/meridian-2-4c.jpeg",
                    "images/meridian/meridian-2-4d.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={projTheme}
                title={"2e. Design Process - Test"}
                compText={
                    [
                        "Originally, we had planned to conduct three user research studies, but we knew we had to be flexible on the details depending on the user feedback we received.",
                        "We ended up conducting the following studies:",
                        "- Unmoderated Desktop Usability Study",
                        "- Unmoderated Mobile Usability Study",
                        "- A/B Test",
                        "We continuously iterated on the MVP design as we conducted these studies and received new information and user feedback.",
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-2-5a.jpeg",
                    "images/meridian/meridian-2-5b.jpeg",
                ]}
            />
            {/* <ProjSectionChallengeGoals
                color={"rgb(250, 250, 250)"}
                title={"Challenge/Goals"}
                compText={
                    [
                        "The challenge and goal for UX on Meridian was to research and design the product’s UX while working with the development team to complete building the MVP by the end of the year.",
                        "Specifically, we had committed to delivering:",
                        " -  Competitor and User research",
                        " - A responsive MVP design/wireframe to be used for demo purposes and for the dev team's reference",
                        " - A style guide to help the team update and improve the product past our engagement deadline"
                        // 'The challenge and goal for UX on Meridian was to research and design the product’s UX while working with the development team to complete building the MVP by the end of the year. Specifically, we had committed to delivering background/competitor research, a responsive wireframe of the MVP to be used for demo purposes and for the dev team to use as reference, and finally a style guide to help the team update and improve the product even past our engagement deadline. '
                    ]
                }
            /> */}
            {/* <ProjSectionLeft
                color={"white"}
                title={"Timeline, Personas, Initial Research, and Style Guide"}
                compText={
                    [
                        "Our UX timeline was largely based around the three User Testing sessions we aimed to conduct.",
                        "Consumer research, and market analysis were conducted to find high-level areas of opportunity and determine the vision, guiding principles, and features of the initial design.",
                        "We used our initial research to inform our first wireframe design iteration, with the understanding that the dev team would use it to begin development as we continued to iterate and refine the design based on our research.",
                        // "The UX engagement was initially planned to span 5 months, and our timeline was largely based around the three User Testing sessions we aimed to conduct in that time. Consumer research, and market analysis were conducted to find high-level areas of opportunity and determine the vision and guiding principles of the initial design. We used our initial research to inform our first wireframe iteration design, with the understanding that the dev team would use it to begin development at a high level as we continued to iterate and refine the design based on our research."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-TPIS1.jpeg",
                    "images/meridian/meridian-TPIS2.jpeg",
                    "images/meridian/meridian-TPIS3.jpeg",
                    "images/meridian/meridian-TPIS5.jpeg",
                    "images/meridian/meridian-TPIS6.jpeg",
                    "images/meridian/meridian-TPIS7.jpeg",
                    "images/meridian/meridian-TPIS4.jpeg",
                    "images/meridian/meridian-TPIS8.jpeg",
                    "images/meridian/meridian-TPIS9.jpeg",
                    "images/meridian/meridian-TPIS10.jpeg",
                    "images/meridian/meridian-TPIS11.jpeg",
                    "images/meridian/meridian-TPIS12.jpeg",
                ]}
            /> */}
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={""}
                title={"User Research Study 1"}
                compText={
                    [
                        "Once the initial wireframe design was complete, we were ready to conduct our first User Research Study.",
                        "We planned an Un/Moderated Desktop Usability study, and our goal was to provide an assessment of the initial wireframe, determine positive/negative elements of the design, and aggregate our findings to drive product development.",
                        "We found that:",
                        " - Meridian’s design tested very well with users, and scored higher than most competitors!",
                        " - Users thought Meridian was ‘high quality, professional, and easy to use.’",
                        " - The v1 design had no major issues, but we did discover a few small UI issues we needed to fix in the design/wireframe before moving forward.",
                        // "Once the initial wireframe design was complete, we were ready to conduct our first User Research Study. This study was an Un/Moderated Desktop Usability study, and our goal was to provide an assessment of the initial Meridian wireframe, determine positive/negative elements and features of the design, and aggregate our findings to drive product development of Meridian."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-2-5c.jpeg",
                    "images/meridian/meridian-2-5c1.jpeg",
                    "images/meridian/meridian-2-5c2.jpeg",
                    "images/meridian/meridian-2-5c3.jpeg",
                    "images/meridian/meridian-2-5d.jpeg",
                    "images/meridian/meridian-2-5e.jpeg",
                    "images/meridian/meridian-2-5f.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={""}
                title={"User Research Study 2"}
                compText={
                    [
                        "After updating the wireframe design using our findings, we then completed the mobile/tablet versions of the design which set us up nicely to run our second user research study.",
                        "Our second study was an Un/Moderated Mobile Usability study, and our goal here was the same as our first study but for the new mobile version of the design.",
                        "We found that:",
                        " - Meridian performed significantly better than its competitors this round.",
                        " - The additional information like ‘procedure/recovery information’ was considered ‘brilliant’ by users.",
                        " - Users expressed difficulty understanding 1. How to get the most accurate estimate, and 2. Understanding how an estimated procedure cost is calculated and would affect their insurance."
                        // "After completing User Research Study 1, we turned our findings into a list of issues and recommendations which were used in order to iterate upon the initial wireframe design. After updating the wireframe design using our findings, we then completed the mobile version of the design which set us up nicely to run our second user research study. Our second user research study was an Un/Moderated Mobile Usability study, which was aimed at providing an assessment of the mobile version of Meridian, determine positive/negative elements and features of the design, and continue to drive product development for Meridian."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-2-5g.jpeg",
                    "images/meridian/meridian-2-5h.jpeg",
                    "images/meridian/meridian-2-5i.jpeg",
                    "images/meridian/meridian-2-5j.jpeg",
                    "images/meridian/meridian-2-5k.jpeg",
                ]}
            />
            <ProjSectionLeft
                bColor={"rgb(250, 250, 250)"}
                color={""}
                title={"User Research Study 3"}
                compText={
                    [
                        "Based on the findings of our second study, we decided to conduct an A/B Study to test new concepts for the two items users struggled with in the previous study.",
                        // "We wanted to determine which design was better by assessing efficiency, user perceptions, and reasons why one design is preferred over another.",
                        "We found that:",
                        " - The two new concepts tested similarly to the original designs in terms of Task Success, but outperformed the original design in Task Efficiency!",
                        " - The new concepts also both highly outperformed the originals in terms of User Design Preference.",
                        " - It was clear from the data that the new concepts we were testing should be implemented in the MVP design.",
                        // "Once we completed the second User Research Study, we used our findings to iterate and improve the mobile/tablet/desktop versions of the wireframe design. One notable finding was that users had trouble understanding how to get the most accurate estimate and how the cost of care is determined with their insurance. Knowing that, our third user research study focused on solving this issue by comparing a new design in an Unmoderated Desktop A/B Study. Specifically, we wanted to determine which design was better by assessing efficiency, user perceptions, and reasons why one design is preferred over another."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-2-5l.jpeg",
                    "images/meridian/meridian-2-5m.jpeg",
                    "images/meridian/meridian-2-5n.jpeg",
                    "images/meridian/meridian-2-5o.jpeg",
                    "images/meridian/meridian-2-5p.jpeg",
                    "images/meridian/meridian-2-5q.jpeg",
                ]}
            />
            <ProjSectionSubSec
                bColor={"white"}
                color={projTheme}
                title={"3. Implementation and Handoff"}
                compText={
                    [
                        "After creating the responsive MVP design, rigorously testing it with users, and creating multiple useful artifacts for the Devs and larger team, we were at the end of our engagement and ready to handoff all of our work.",
                        "We were already working with the Dev team to implement the design by the end of our enagement, but after our last User Reseacrh Study our main focus became documentation and full implementation."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-3-1.jpeg",
                    "images/meridian/meridian-3-2.jpeg",
                    "images/meridian/meridian-3-3.jpeg",
                    "images/meridian/meridian-3-4.jpeg",
                    "images/meridian/meridian-3-5.jpeg",
                    "images/meridian/meridian-3-6.jpeg",
                    "images/meridian/meridian-3-7.jpeg",
                ]}
            />
            <ProjSectionSubSec
                bColor={"white"}
                color={projTheme}
                title={"4. Takeaways"}
                compText={
                    [
                        "At the end of the day, I found working on the Meridian team to be an amazing experience. It was exciting to be working on a new product from the ground up, and it was a joy working collaboratively with such a passionate and talented group.",
                        "I found this project a large success not only because we delivered on all of our commitments with lots of positive feedback, but also because of how we fundamentally executed and implemented the UX process with the team."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-4-1.jpeg",
                ]}
            />
            {/* <ProjSectionLeft
                color={"white"}
                title={"Final MVP Design"}
                compText={
                    [
                        "Having completed all of our planned user research studies and improving Meridian's design/wireframe each step of the way, we were right on schedule to complete the MVP by end of year!",
                        "In addition to the MVP design, we also delivered a style guide to help with future development and features after we off-boarded.",
                        // "Having completed all of our planned user research studies and improving Meridian's design/wireframe each step of the way, we were right on schedule to complete the MVP. In addition to the MVP design, we also delivered a style guide to help with future development and features after we off-boarded."
                    ]
                }
                carouselImages={[
                    "images/meridian/meridian-FMVP0.jpeg",
                    "images/meridian/meridian-FMVP1.jpeg",
                    "images/meridian/meridian-FMVP2.jpeg",
                    "images/meridian/meridian-FMVP3.jpeg",
                ]}
            /> */}



        </motion.div>
    )
}
export default MeridianPage;